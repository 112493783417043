import { React, useEffect, useState } from "react";
import siteLogo from "../assets/images/site-logo.webp";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import bhubhaneswarimg from "../assets/images/bhubaneswar.webp";
import siliguririmg from "../assets/images/siliguri.webp";
import { useCity } from "../constants/CityProvider";

import ApiService from "../constants/ApiService";
import TranslateComponent from "../constants/TranslateComponent";

function Header({ siteData, isShowLoader }) {
  //it is used for navigation
  const { getList } = ApiService();

  const navigate = useNavigate();
  let location = useLocation();
  let [currentPathName, setcurrentPathName] = useState("/");
  const [width, setWidth] = useState(window.innerWidth);
  let [siteInfo, setSiteInfo] = useState({});
  const { setSelectedCity, selectedCity } = useCity();

  // const [selectedCity, setSelectedCity] = useState("Cities");
  const [cities, setCities] = useState([]);

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    isShowLoader(true);
    if (path !== null && path !== "") {
      setcurrentPathName(path);
      navigate(path);
    }
  };

  //set window width
  let handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  let opencitymodal = (e) => {
    e.preventDefault();

    let $chooseCity = $("#choosecity");
    if ($chooseCity.is(":visible")) {
      $chooseCity.hide();
    } else {
      $chooseCity.show();
    }
  };

  let closeModal = async () => {
    $("#choosecity").hide();
    const response = await fetch("https://ipinfo.io/json");
    const data = await response.json();
    if (data && data.city) {
      const userCity = data.city; // Get the user's city from the response

      // Check if the user's city matches any of the cities displayed
      const matchingCity = cities.find((city) => city.name === userCity);
      if (matchingCity) {
       setTimeout(() => {
         setSelectedCity(matchingCity.name); // Set the selected city
         localStorage.setItem("cityId", matchingCity.id); // Store the city ID
         localStorage.setItem("city", matchingCity.name); // Store the city name
         // Notify user about the city set
        //  toast.info(`City set to your location: ${matchingCity.name}`);
       }, 5000);
      }
    }
  };

  let handleCitySelect = (cityName, cityId) => {
    const currentCity = localStorage.getItem("city");

    if (currentCity === cityName) {
      setSelectedCity(cityName);
      $("#choosecity").hide();
      return; // Exit the function without navigating
    }

    setSelectedCity(cityName);
    localStorage.setItem("cityId", cityId);
    localStorage.setItem("city", cityName);

    // Check if the current path is "car-service-price"
    if (location.pathname === "/car-service-price") {
      toast.info("City changed. Redirecting to the previous page.");

      setTimeout(() => {
        navigate("/car-repair");
      }, 3000);
    }

    $("#choosecity").hide();
  };

  const getCities = async () => {
    try {
      let dataInfo = await getList("city-list", {});
      if (dataInfo.responseCode === 200) {
        const allowedCities = [
          "Kolkata",
          "Siliguri",
          "Bhubaneswar",
          "Patna",
          "Cuttack",
          "Gaya",
        ];

        const filteredCities = dataInfo.responseData.filter((city) =>
          allowedCities.includes(city.name)
        );

        const sortedCities = filteredCities.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        // const sortedCities = dataInfo.responseData.sort((a, b) =>
        //   a.name.localeCompare(b.name)
        // );

        setCities(sortedCities);

        // setCities(dataInfo.responseData);
      } else {
        setCities([]);
      }
    } catch (error) {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setSiteInfo(siteData);

    getCities();
  }, [siteData]);

  useEffect(() => {
    isShowLoader(true);

    window.scrollTo(0, 0);

    var elements = document.getElementsByClassName("slimmenu");
    if (width <= 768) {
      elements[0].style.display = "none";
    }
  }, [location]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

  useEffect(() => {
    setcurrentPathName(location.pathname);

    //scroll to top
    window.scrollTo(0, 0);

    $("body").prepend($('<div class="layoutovarlay"></div>'));
    $(".navigation").prepend(
      $('<div class="layoutovarlay2 menu-collapser"></div>')
    );
    $("body").removeClass("layout-expanded2");
  }, [location]);

  useEffect(() => {
    $("body").click();
  }, []);

  useEffect(() => {
    const storedCityId = localStorage.getItem("cityId");

    // Check if cityId is '1' and cityName is 'bangalore'
    if (storedCityId === "1") {
      // Remove both from local storage
      setSelectedCity("Cities");
      localStorage.removeItem("cityId");
      localStorage.removeItem("city");
    } else if (storedCityId) {
      const selectedCityData = cities.find(
        (city) => city.id.toString() === storedCityId
      );
      if (selectedCityData) {
        setSelectedCity(selectedCityData.name);
      }
    } else {
      setSelectedCity("Cities"); // default value
    }
  }, [cities]);

  useEffect(() => {
    const storedCityId = localStorage.getItem("cityId");
    // Check if cityId is '1' and cityName is 'bangalore'
    if (storedCityId === "1") {
      // Remove both from local storage
      setSelectedCity("Cities");
      localStorage.removeItem("cityId");
      localStorage.removeItem("city");
    }
    const intervalId = setInterval(() => {
      let storedCityId = localStorage.getItem("cityId");
      let $chooseCity = $("#choosecity");

      if (!storedCityId) {
        $chooseCity.addClass("show");
        $chooseCity.show();
      } else {
        $chooseCity.removeClass("show");
        $chooseCity.hide();
        clearInterval(intervalId);
      }
    }, 10000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <section class="header_section headercontainer">
        <div class="container">
          
          <div class="row headerrw">
            <div class="header_col1 header_col">
              <div class="site_logo">
                <a href="" onClick={(e) => changeRoute(e, "/")}>
                  <img
                    src={siteLogo}
                    alt="OtoBix"
                    class="img-fluid"
                    loading="lazy"
                    fetchpriority="low"
                    width={131}
                    height={40}
                  />
                </a>
              </div>
            </div>
            <div
              className="dropdown"
              style={{ maxWidth: "100% !important", width: "0" }}
            >
              <a
                className="btn btn-sm dropdown-toggle btn-radius city-btn"
                onClick={(e) => opencitymodal(e)}
              >
                {selectedCity}
              </a>
            </div>
            <div class="header_col2 header_col">
              <div class="header_menu">
                <ul id="navigation" class="slimmenu">
                  <li
                    class={currentPathName === "/" ? "current-menu-item" : ""}
                  >
                    <a href="" onClick={(e) => changeRoute(e, "/")}>
                      Home
                    </a>
                  </li>
                  <li
                    class={
                      currentPathName === "/about-us" ? "current-menu-item" : ""
                    }
                  >
                    <a
                      href="/about-us"
                      onClick={(e) => changeRoute(e, "/about-us")}
                    >
                      About Us
                    </a>
                  </li>
                  <li
                    class={
                      currentPathName === "/sell-a-car"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      href="/sell-a-car"
                      onClick={(e) => changeRoute(e, "/sell-a-car")}
                    >
                      Sell A Car
                    </a>
                  </li>
                  <li
                    class={
                      currentPathName === "/buy-a-car"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      href="/buy-a-car"
                      onClick={(e) => changeRoute(e, "/buy-a-car")}
                    >
                      Buy A Car
                    </a>
                  </li>
                  <li class="has-submenu">
                    <a href="" onClick={(e) => e.preventDefault()}>
                      Finance
                    </a>
                    <ul class="sub-menu">
                      <li
                        class={
                          currentPathName === "/new-car-finance"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/new-car-finance"
                          onClick={(e) => changeRoute(e, "/new-car-finance")}
                        >
                          New Car Finance
                        </a>
                      </li>
                      <li
                        class={
                          currentPathName === "/pre-owned-car-finance"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/pre-owned-car-finance"
                          onClick={(e) =>
                            changeRoute(e, "/pre-owned-car-finance")
                          }
                        >
                          Pre - Owned Car Finance
                        </a>
                      </li>
                      <li
                        class={
                          currentPathName === "/loan-against-car"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/loan-against-car"
                          onClick={(e) => changeRoute(e, "/loan-against-car")}
                        >
                          Loan Against Car
                        </a>
                      </li>
                    </ul>
                    <span class="sub-toggle">
                      <i>▼</i>
                    </span>
                  </li>
                  <li
                    class={
                      currentPathName === "/insurance"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      className="insurancenav"
                      href="/insurance"
                      onClick={(e) => changeRoute(e, "/insurance")}
                    >
                      Insurance
                    </a>
                  </li>
                  <li
                    class={
                      currentPathName === "/wealth-management-services"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      href="/wealth-management-services"
                      onClick={(e) =>
                        changeRoute(e, "/wealth-management-services")
                      }
                    >
                      WMS
                    </a>
                  </li>
                  <li class="has-submenu">
                    <a href="" onClick={(e) => e.preventDefault()}>
                      Inventory
                    </a>
                    <ul class="sub-menu">
                      <li
                        class={
                          currentPathName === "/inventory-for-customers"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/inventory-for-customers"
                          onClick={(e) =>
                            changeRoute(e, "/inventory-for-customers")
                          }
                        >
                          For Customers
                        </a>
                      </li>
                      <li
                        class={
                          currentPathName === "/inventory-for-car-dealers"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/inventory-for-car-dealers"
                          onClick={(e) =>
                            changeRoute(e, "/inventory-for-car-dealers")
                          }
                        >
                          For Car Dealers
                        </a>
                      </li>
                    </ul>
                    <span class="sub-toggle">
                      <i>▼</i>
                    </span>
                  </li>

                  <li
                    class="has-submenu"
                    style={{ marginLeft: "6px", marginRight: "6px" }}
                  >
                    <a href="" onClick={(e) => e.preventDefault()}>
                      Car Services
                    </a>
                    <ul class="sub-menu">
                      <li
                        class={
                          currentPathName === "/car-repair"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/car-repair"
                          onClick={(e) => changeRoute(e, "/car-repair")}
                        >
                          Car Repair
                        </a>
                      </li>
                      <li
                        class={
                          currentPathName === "/road-side-assistance"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/road-side-assistance"
                          onClick={(e) =>
                            changeRoute(e, "/road-side-assistance")
                          }
                        >
                          Road Side Assistance (RSA)
                        </a>
                      </li>
                      <li
                        class={
                          currentPathName === "/warranty"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/warranty"
                          onClick={(e) => changeRoute(e, "/warranty")}
                        >
                          Warranty
                        </a>
                      </li>

                      <li
                        class={
                          currentPathName === "/car-service-booking-status"
                            ? "current-menu-item"
                            : ""
                        }
                      >
                        <a
                          href="/car-service-booking-status"
                          onClick={(e) =>
                            changeRoute(e, "/car-service-booking-status")
                          }
                        >
                          Track The Request
                        </a>
                      </li>
                    </ul>
                    <span class="sub-toggle">
                      <i>▼</i>
                    </span>
                  </li>

                  {/* <li
                    class={
                      currentPathName === "/car-services"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      className="carservicesnav"
                      href="/car-services"
                      onClick={(e) => changeRoute(e, "/car-services")}
                    >
                      Car Services
                    </a>
                  </li> */}

                  <li
                    class={
                      currentPathName === "/contact-us"
                        ? "current-menu-item"
                        : ""
                    }
                  >
                    <a
                      href="/contact-us"
                      onClick={(e) => changeRoute(e, "/contact-us")}
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="header_col3 header_col">
              <div class="header_contact">
                <div class="header_phone d-flex align-items-center justify-content-space-between">
                  <a
                    aria-label="Phone Number"
                    href={"tel:" + siteInfo?.site_phone_number}
                    class="d-flex align-items-center"
                  >
                    <span class="icon-Vector-1 icon"></span>
                    <span class="phone_text">
                      {siteInfo?.site_phone_number
                        ? siteInfo?.site_phone_number
                        : "+91 90888 22999"}
                    </span>
                  </a>
                </div>
                <div class="header_email">
                  <a
                    aria-label="Email"
                    href={"mailto:" + siteInfo?.site_email}
                    class="d-flex align-items-center"
                  >
                    <span class="icon-Vector icon"></span>
                    <span class="email_text">
                      {siteInfo?.site_email
                        ? siteInfo?.site_email
                        : "info@otobix.in"}
                    </span>
                  </a>
                </div>
                {/* <div class="header_email">
                  <a
                    href="https://wa.me/919088822999"
                    aria-label="whatsapp"
                    target="_blank"
                    class="d-flex align-items-center"
                  >
                    <span class="icon-whatsapp icon" style={{ marginTop:"-6px" }}></span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* choose your city model*/}

      <div
        class="modal choosecity commonModal"
        id="choosecity"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div class="modal-contents">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ color: "black" }}
              >
                Choose Your City
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              ></button>
            </div>
            <div class="modal-body">
              <div className="city-container">
                {cities.map((city) => {
                  const imageUrl =
                    city.name.toLowerCase() === "mumbai"
                      ? "https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/mumbai-1.webp"
                      : city.name.toLowerCase() === "bhubaneswar"
                      ? bhubhaneswarimg
                      : city.name.toLowerCase() === "siliguri"
                      ? siliguririmg
                      : `https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/${city.name.toLowerCase()}.webp`;

                  return (
                    <div
                      className="text-center img-hover-zoom--colorize"
                      key={city.id}
                    >
                      <a
                        key={city.id}
                        className="city-pointer"
                        onClick={() => handleCitySelect(city.name, city.id)}
                      >
                        <img
                          src={imageUrl}
                          alt={city.name}
                          className="rounded-circle border border-light m-2"
                          loading="lazy"
                          fetchpriority="low"
                          onError={(e) => {
                            e.target.onerror = null; // Prevents looping
                            e.target.src =
                              "https://placehold.co/100x100?text=No+Image";
                          }}
                          style={
                            city.name.toLowerCase() === "bhubaneswar" ||
                            city.name.toLowerCase() === "siliguri"
                              ? { width: "100px", height: "100px" } // Set your desired dimensions
                              : {}
                          }
                        />
                        <h5 className="city-name">{city.name}</h5>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        class="modal choosecity commonModal"
        id="choosecity"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-contents">
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ color: "black" }}
              >
                Choose Your City
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              ></button>
            </div>
            <div class="modal-body">
              <div className="city-container">
                <div
                  ngbautofocus=""
                  className="text-center img-hover-zoom--colorize"
                >
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Bangalore")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/bangalore.webp"
                      alt="Bangalore"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Bangalore</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Guwahati")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/guwahati.webp"
                      alt="Guwahati"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Guwahati</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Hyderabad")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/hyderabad.webp"
                      alt="Hyderabad"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Hyderabad</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Gurgaon")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/gurgaon.webp"
                      alt="Gurgaon"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Gurgaon</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Pune")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/pune.webp"
                      alt="Pune"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Pune</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Kolkata")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/kolkata.webp"
                      alt="Kolkata"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Kolkata</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Jaipur")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/jaipur.webp"
                      alt="Jaipur"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Jaipur</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Patna")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/patna.webp"
                      alt="Patna"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Patna</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Mumbai")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/mumbai-1.webp"
                      alt="Mumbai"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Mumbai</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Ranchi")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/ranchi.webp"
                      alt="Ranchi"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Ranchi</h5>
                  </a>
                </div>
                <div className="text-center img-hover-zoom--colorize">
                  <a
                    className="city-pointer"
                    onClick={() => handleCitySelect("Aurangabad")}
                  >
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/prodimages.automovill.com/icon/aurangabad.webp"
                      alt="Aurangabad"
                      className="rounded-circle border border-light m-2"
                    />
                    <h5 className="city-name">Aurangabad</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <a href="https://wa.me/919088822999" class="float" target="_blank">
        <i class="icon-whatsapp my-float"></i>
      </a>
    </>
  );
}

export default Header;
