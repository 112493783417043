import React, { useEffect, useRef } from "react";
import { useCity } from "./CityProvider";

const TranslateComponent = () => {
  const googleTranslateRef = useRef(null);
  const { selectedCity } = useCity(); // Use the context to get the selected city

  // Define a mapping of cities to their respective languages
  const cityLanguageMapping = {
    Kolkata: "bn", // Bengali
    Patna: "hi", // Hindi (Bihari is often translated to Hindi)
    Bhubaneswar: "or", // Odia
    Siliguri: "bn", // Bengali
  };

  useEffect(() => {
    let intervalId = null;

    const checkGoogleTranslate = () => {
      if (
        window.google &&
        window.google.translate &&
        window.google.translate.TranslateElement.InlineLayout
      ) {
        clearInterval(intervalId);

        const language = cityLanguageMapping[selectedCity]||'en'; 

        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "bn,hi,or,en", 

            // includedLanguages:
            //   "af,ach,ak,am,ar,az,be,bem,bg,bh,bn,br,bs,ca,chr,ckb,co,crs,cs,cy,da,de,ee,el,en,eo,es,es-419,et,eu,fa,fi,fo,fr,fy,ga,gaa,gd,gl,gn,gu,ha,haw,hi,hr,ht,hu,hy,ia,id,ig,is,it,iw,ja,jw,ka,kg,kk,km,kn,ko,kri,ku,ky,la,lg,ln,lo,loz,lt,lua,lv,mfe,mg,mi,mk,ml,mn,mo,mr,ms,mt,ne,nl,nn,no,nso,ny,nyn,oc,om,or,pa,pcm,pl,ps,pt-BR,pt-PT,qu,rm,rn,ro,ru,rw,sd,sh,si,sk,sl,sn,so,sq,sr,sr-ME,st,su,sv,sw,ta,te,tg,th,ti,tk,tl,tn,to,tr,tt,tum,tw,ug,uk,ur,uz,vi,wo,xh,yi,yo,zh-CN,zh-TW,zu",
            layout:
              window.google.translate.TranslateElement.InlineLayout.VERTICAL,
            autoDisplay: false, // Prevent auto display of the translation widget
            defaultLanguage: language, // Set the default language based on selected city
          },
          googleTranslateRef.current
        );
      }
    };


    intervalId = setInterval(checkGoogleTranslate, 100);
    return () => clearInterval(intervalId);
  }, [selectedCity]); // Add selectedCity as a dependency to update when it changes

    return <div ref={googleTranslateRef} style={{ display:"flex",justifyContent:"flex-end" }}></div>;

};

export default TranslateComponent;

